<template>
  <div>
    <template>
      <!-- First Row -->
      <b-card>
        <b-row>
          <h4 class="pl-1 pb-1">Numero de apuesta: {{ $route.params.id }}</h4>
          <b-table md="9" :items="dataItems" :fields="fields" class="scroll" responsive>
            <template #cell(status)="data">
              <span v-if="data.item.status == 'PENDING'"
                style="width: %100; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #03a9f4; color:#FFFFFF;">
                {{ $t('status.pending') }}</span>
              <span v-else-if="data.item.status == 'LOST'"
                style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #f44336; color:#FFFFFF;">
                {{ $t('status.lost') }}</span>
              <span v-else-if="data.item.status == 'WON'"
                style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #8bc34a; color:#FFFFFF;">
                {{ $t('status.won') }}</span>
              <span v-else-if="data.item.status == 'NULL_BET'"
                style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #25476a; color:#FFFFFF;">
                {{ $t('status.null_bet') }}</span>
              <span v-else-if="data.item.status == 'PAID'"
                style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #8bc34a; color:#FFFFFF;">
                {{ $t('status.paid') }}</span>
              <span v-else-if="data.item.status == 'EXPIRED'"
                style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #25476a; color:#FFFFFF;">
                {{ $t('status.expired') }}</span>
              <span v-else-if="data.item.status == 'RETURN'"
                style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #25476a; color:#FFFFFF;">
                {{ $t('status.return') }}</span>
              <span v-else-if="data.item.status == 'INGAME'"
                style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #ffb300; color:#FFFFFF;">
                {{ $t('status.ingame') }}</span>
            </template>
            <template #cell(amount)="data">
              {{ data.item.amount.CURRENCY }}
            </template>
            <template #cell(final_bonus_amount)="data">
              {{ data.item.final_bonus_amount.CURRENCY }}
            </template>
            
            <template v-if="this.$store.state.whitelabelCurrencyNabvar.userInfo.typeUser == 'Root'" #cell(secure_code)="data">
              {{ data.item.rawbet.secure_code }}
            </template>
          </b-table>
        </b-row>
      </b-card>
      <b-card>
        <b-row>
          <h3 class="pl-1 pb-1">{{ $t("labels.ticketdetail") }}</h3>
          <b-table md="9" :items="dataSelelections" :fields="detailFields" class="scroll" responsive>
            <template #cell(bet)="data">
              <b-button variant="outline-primary" :to="{
                name: 'apps-sports-detail',
                params: { id: data.item.id },
              }">{{ data.item.bet }}</b-button>
            </template>
            <template #cell(img)="data">
              <!-- {{data.item.img}} -->
              <img :src="data.item.img" width="40px" />
              <!-- <image-table-field :url="data.item.img"></image-table-field> -->
            </template>
            <template #cell(amount)="data">
              {{ data.item.amount | currency({ symbol: "" }) }}
            </template>
            <template #cell(finalBonusAmount)="data">
              {{ data.item.finalBonusAmount | currency({ symbol: "" }) }}
            </template>
            <template #cell(created)="data">
              {{ data.item.created | moment }} asd
            </template>
            <template #cell(status)="data">
             <span v-if="data.item.game_status === 'PENDING'"
                   style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; vertical-align: middle; border-radius: 10px;background: #03a9f4; color:#FFFFFF;">
                {{ $t('status.pending') }}</span>
              <span v-else-if="data.item.game_status === 'LOST'"
                    style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; vertical-align: middle; border-radius: 10px;background: #f44336; color:#FFFFFF;">
                {{ $t('status.lost') }}</span>
              <span v-else-if="data.item.game_status === 'WON'"
                    style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; vertical-align: middle; border-radius: 10px;background: #8bc34a; color:#FFFFFF;">
                {{ $t('status.won') }}</span>
              <span v-else-if="data.item.game_status === 'NULL_BET'"
                    style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; vertical-align: middle; border-radius: 10px;background: #25476a; color:#FFFFFF;">
                {{ $t('status.null_bet') }}</span>
              <span v-else-if="data.item.game_status === 'PAID'"
                    style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; vertical-align: middle; border-radius: 10px;background: #8bc34a; color:#FFFFFF;">
                {{ $t('status.paid') }}</span>
              <span v-else-if="data.item.game_status === 'EXPIRED'"
                    style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; vertical-align: middle; border-radius: 10px;background: #25476a; color:#FFFFFF;">
                {{ $t('status.expired') }}</span>
              <span v-else-if="data.item.game_status === 'RETURN'"
                    style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; vertical-align: middle; border-radius: 10px;background: #25476a; color:#FFFFFF;">
                {{ $t('status.return') }}</span>
              <span v-else-if="data.item.game_status === 'INGAME'"
                    style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; vertical-align: middle; border-radius: 10px;background: #ffb300; color:#FFFFFF;">
                {{ $t('status.ingame') }}</span>
            </template>
            <template #cell(juego)="data">
              <section
                  v-if="data"
                  class="d-flex flex-column justify-content-center" style="min-width: max-content;gap: 5px">
                <div class="d-flex font-weight-bolder justify-content-around">
                  <span>{{data.item.juego}}</span>
                  <div class="d-flex align-items-center" style="gap: 4px">
                    <span>{{data.item.id}}</span>
                    <b-button
                        v-clipboard:copy="data.item.id"
                        v-clipboard:success="onCopy"
                        v-clipboard:error="onError"
                        variant="transparent"
                        class="p-0 m-0"
                    >
                      <feather-icon
                          icon="ClipboardIcon"
                          class="cursor-pointer text-primary"
                          size="15"
                      />
                    </b-button>
                  </div>
                </div>
                <div class="d-flex align-items-center" style="gap: 5px">
                  <img :src="data.item.img" width="15px" height="15px" alt="league_name" />
                  ({{data.item.leagueName}}) - 
                  {{data.item.status}} -
                  {{data.item.start_date}}
                </div>
              </section>
            </template>
          </b-table>
        </b-row>
        <div class="d-flex mt-2">
          <b-button type="button" variant="outline-secondary"
            to="/apps/reports/sports/betsPlaced">
            {{ $t("buttons.back") }}
          </b-button>
          <!-- Boton para cancelar -->
          <!--
          <span v-if="
            typeUser === 'Agent' &&
            rows.status === 'PENDING' &&
            this.diffDate <= 10
          ">
            <b-button variant="danger" @click="cancelTicket(rows.bet)" :disabled="cancelTicketButton">
              Cancelar
            </b-button>
          </span>
          <span v-if="typeUser === 'Root' || typeUser === 'Admin'">
            <span v-if="rows.status === 'PENDING'">
              <b-button variant="danger" @click="cancelTicket(rows.bet)" :disabled="cancelTicketButton">
                Cancelar
              </b-button>
            </span>
          </span>
            -->
        </div>
      </b-card>
      <!-- Form Actions -->
    </template>
  </div>
  <!-- <div v-else>
        <template>
           
            <b-row>
                hi there! 2
            </b-row>
        </template>
    </div> -->
</template>

<script>
import store from "@/store";
import router from "@/router";
import axios from "@axios";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ref, onUnmounted } from "@vue/composition-api";
import {BRow, BCol, BAlert, BLink, BButton} from "bootstrap-vue";
import betsPlacedStoreModule from "./betsPlacedStoreModule";
import Clipboard from "@/views/extensions/clipboard/Clipboard.vue";

export default {
  components: {
    BButton,
    Clipboard,
    BRow,
    BCol,
    BAlert,
    BLink,
  },
  data() {
    return {
      fields: [
        { label: "Origen", key: "source" },
        { label: "Ticket Id", key: "bet" },
        { label: "Usuario", key: "username" },
        { label: "Fecha", key: "created" },
        { label: "Estado", key: "status" },
        { label: "Monto Apostado", key: "amount" },
        { label: "Monto a Cobrar", key: "final_bonus_amount" },
        { label: "Codigo Seguridad", key: "secure_code" },
      ],
      detailFields: [
        { label: "Tipo", key: "type" },
        { label: "Punto", key: "punto" },
        { label: "Valor", key: "valor" },
        { label: "Periodo", key: "periodo" },
        { label: "Estado", key: "status" },
        { label: "Juego", key: "juego" },
      ],
      dataItems: [],
      rows: [],
      dataTicket: [],
      dataSelelections: [],
      buttonTicket: false,
      SPORT_APP_STORE_MODULE_NAME: "app-sport" 
    };
  },
  computed: {
    cancelTicketButton() {
      return this.buttonTicket;
    },
  },
  destroyed() {
    // unregister module store
    if(store.hasModule(this.SPORT_APP_STORE_MODULE_NAME)){
      store.unregisterModule(this.SPORT_APP_STORE_MODULE_NAME);
    }
  },
  created() {
    if (!store.hasModule(this.SPORT_APP_STORE_MODULE_NAME))
      store.registerModule(this.SPORT_APP_STORE_MODULE_NAME, betsPlacedStoreModule);
    this.idUserSelect = JSON.parse(localStorage.getItem("userData"))._id;
    this.userNameDestination = JSON.parse(
      localStorage.getItem("userData")
    ).userName;
    this.typeUser = JSON.parse(localStorage.getItem("userData")).typeUser;

    store
      .dispatch("app-sport/fetchSport", { id: router.currentRoute.params.id, whitelabel: this.$store.state.whitelabelCurrencyNabvar.whitelabel._id })
      .then((response) => {
        this.rows = response.data.SportsBookPlay.response;
        this.dataItems.push(response.data.SportsBookPlay.response);
        let ticketDetail = response.data.SportsBookPlay.response.selections;
        let detailFiltered = [];
        ticketDetail.map(function ($itemDetail) {
          Object.keys($itemDetail).map(function ($detailIndex) {
            detailFiltered.push($itemDetail[$detailIndex]);
          });
        });
        detailFiltered.forEach((e) =>
          this.dataSelelections.push({
            id: e.event.event_id,
            type: e.outcome.name,
            punto: e.select.text,
            valor: e.priceAmerican + "[" + e.priceDecimalPercentage + "]",
            periodo: e.select.time,
            game_status: e.status,
            status:
              e.event.status == "NSY"
                ? "No iniciado"
                : e.event.status == "inprogress"
                  ? "En progreso"
                  : e.event.status == "manual"
                    ? "Manual"
                    : e.event.status == "Finished"
                      ? "Finalizado"
                      : e.event.status == "Graded"
                        ? "Cerrado"
                        : e.event.status == "grade_bets"
                          ? "Por Cerrar Jugadas"
                          : e.event.status == "Abandoned"
                            ? "Abandonado"
                            : e.event.status == "Cancelled"
                              ? "Cancelado"
                              : e.event.status == "Interrupted"
                                ? "Interrumpido"
                                : e.event.status == "Postponed"
                                  ? "Pospuesto"
                                  : "",
            juego: e.event.name,
            img: `https://s3.amazonaws.com/sportsbookplay/assets/icon/sport/200x200/${e.event.sport.id}.png`,
            leagueName: e.event.league.name,
            start_date: e.event.start_date || e.event.startDate
          })
        );
        console.log(this.dataSelelections)
        const createdTicket = response.data.SportsBookPlay.response.created;
        // Diferencias entre fechas para sacar el tiempo transcurrido
        this.diffDate = this.diffDate(createdTicket);
      })
      .catch((error) => {
        console.log("ENTRE IN ERROR");
      });
  },
  methods: {
    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Text copied',
          icon: 'BellIcon',
        },
      })
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Failed to copy texts!',
          icon: 'BellIcon',
        },
      })
    },
    confirmTicket(ticketId, amount_real) {
      const VUE_APP_URL = process.env.VUE_APP_URL;
      const userId = this.idUserSelect;
      const currency = this.$store.state.whitelabelCurrencyNabvar.currency;

      const params = {
        currency: currency,
        amount: amount_real,
        userId: userId,
        ticket: ticketId,
      };
      const result = axios
        .post(`${VUE_APP_URL}/confirmDetail`, params)
        .then((r) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: r.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => console.log(error.response));
      return result;
    },
    cancelTicket(ticket) {
      const username = this.userNameDestination;
      const VUE_APP_URL = process.env.VUE_APP_URL;
      const currency = this.$store.state.whitelabelCurrencyNabvar.currency;

      const params = {
        ticketId: ticket,
        // currency: currency,
        username: username,
        whitelabel: this.$store.state.whitelabelCurrencyNabvar.whitelabel._id
      };
      const result = axios
        .post(`${VUE_APP_URL}/cancelTicket`, params)
        .then((r) => {
          if (r.status === 200) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: r.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });

            setTimeout(function () {
              window.location.reload()
            }, 4000);

            this.buttonTicket = true
          }
        }
        )
        .catch((error) => console.log(error.response));
      return result;
    },
    diffDate(createdTicket) {
      const endTicket = moment(createdTicket).format("YYYY/MM/DD");
      const now = moment(new Date()); // fecha de hoy
      const end = moment(endTicket); // fecha del ticket
      const duration = moment.duration(now.diff(end));
      return duration.minutes();
    },
  },
  mounted() {
    const VUE_APP_URL = process.env.VUE_APP_URL;
    const ticketId = router.currentRoute.params.id;
    const currency = this.$store.state.whitelabelCurrencyNabvar.currency;
    const params = {
      ticketId: ticketId,
      currency: currency,
      userId: this.idUserSelect,
      whitelabel: this.$store.state.whitelabelCurrencyNabvar.whitelabel._id
    };
    const result = axios
      .post(`${VUE_APP_URL}/showTicketsDetail`, params)
      .then((r) => {
        this.dataTicket = r.data;
        /*this.$toast({
            component: ToastificationContent,
            props: {
              title: r.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });*/
      })
      .catch((error) => console.log(error.response));
    return result;
  },
};
</script>

<style>
</style>
